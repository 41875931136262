import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "../../../../../../../../components/Grids/DataGrid.jsx";
import { GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { EditableCheckBoxCell } from "../../../../../../../../components/GridColumnComponents/EditableCheckBoxCell.jsx";
import { BaseCell } from "../../../../../../../../components/GridColumnComponents/BaseCell.jsx";
import StringHelper from "../../../../../../../../resources/StringHelper.js";
import { RoleContext } from "../../../../Providers/RoleProvider.jsx";

/**
 * @function BaseContent
 * @param props.childPermissionGroups {Array|Object}
 * @param props.columns {Array|String}
 * @return {Element}
 * @constructor
 */
const BaseContent = (props) => {
    const { childPermissionGroups, columns = [] } = props;
    const [data, setData] = useState(childPermissionGroups ?? []);
    const [reloadGrid, setReloadGrid] = useState(Math.random());
    const { updatePermissions } = useContext(RoleContext);

    const HeaderCell = (props) => (
        <BaseCell
            {...props}
            style={{
                fontWeight: "500",
                textTransform: "uppercase",
                padding: 0,
                backgroundColor: "white",
                borderWidth: 0,
            }}
        />
    );

    const triggerGridReload = () => setReloadGrid(Math.random());

    const handleToggle = (value, field, dataItem) => {
        const updatedItem =
            field === "fullAccess"
                ? handleFullAccessToggle(dataItem, value)
                : handleIndividualToggle(dataItem, field, value);

        updateDataAndReload(updatedItem, dataItem.id);
    };

    // If User selects full access as true, we want to propagate that change to the other toggles so they are all True
    // Else, if false we will do the opposite
    const handleFullAccessToggle = (dataItem, value) => {
        const booleanUpdates = Object.entries(dataItem).reduce(
            (acc, [key, val]) => {
                if (typeof val?.hasPermission === "boolean") {
                    acc[key] = {
                        hasPermission: value,
                        id: dataItem[key].id,
                    };
                }
                return acc;
            },
            {}
        );

        return {
            ...dataItem,
            fullAccess: value,
            ...booleanUpdates,
        };
    };

    // Individual also takes care of indeterminate state for 'fullAccess'
    // I.E If value is true, 'fullAccess' is indeterminate,
    // If value is false AND all other values are false, 'fullAccess' is false.
    // This also applies for true, and setting as indeterminate based on other toggles
    const handleIndividualToggle = (dataItem, field, value) => {
        const baseField = field.split(".")[0];
        const baseUpdate = {
            ...dataItem,
            [baseField]: {
                hasPermission: value,
                id: dataItem[baseField].id,
            },
        };

        const relevantToggles = getRelevantToggles(dataItem, baseField);
        const fullAccessStatus = determineFullAccessStatus(
            relevantToggles,
            value
        );

        return {
            ...baseUpdate,
            fullAccess: fullAccessStatus,
        };
    };

    // We do not want to be checking 'id' or 'name' as they are non-boolean.
    // We also do not check 'fullAccess' as it would sully the data, and 'currentField'
    // is not checked because it is stale data in the params (dataItem)
    const getRelevantToggles = (dataItem, currentField) => {
        const excludedFields = ["id", "name", "fullAccess", currentField];

        return Object.entries(dataItem)
            .filter(([key]) => !excludedFields.includes(key))
            .map(([, value]) => value);
    };

    const determineFullAccessStatus = (toggles, currentValue) => {
        if (
            toggles.every((toggle) => toggle.hasPermission === true) &&
            currentValue
        ) {
            return true;
        }
        if (toggles.every((value) => value === false) && !currentValue) {
            return false;
        }
        return null;
    };

    const updateDataAndReload = (updatedItem, itemId) => {
        const newData = [
            ...data.map((item) => (item.id === itemId ? updatedItem : item)),
        ];
        setData(newData);
        sendAPIUpdate(newData);
        triggerGridReload();
    };

    const sendAPIUpdate = (data) => {
        const updatingPermissions = [];
        const rows = Object.entries(data).map(([, value]) => value);

        // Yes this is unoptimized
        for (let i = 0; i < rows.length; i++) {
            const excludedFields = ["id", "name", "fullAccess"];
            const permissions = Object.entries(rows[i])
                .filter(([key]) => !excludedFields.includes(key))
                .map(([, value]) => value);

            for (let i = 0; i < permissions.length; i++) {
                updatingPermissions.push(permissions[i]);
            }
        }
        updatePermissions(updatingPermissions);
    };

    return (
        <DataGrid
            key={reloadGrid}
            data={data}
            disabledPagination={true}
            showColumnsMultiSelect={false}
            useInlineEdit={true}
            cells={{
                headerCell: HeaderCell,
            }}
        >
            <GridNoRecords>
                <React.Fragment />
            </GridNoRecords>
            <GridColumn
                field={"fullAccess"}
                title={" "}
                width={50}
                cell={(props) => (
                    <EditableCheckBoxCell
                        {...props}
                        editField={"name"}
                        size={"large"}
                        style={{
                            paddingTop: 20,
                            paddingBottom: 20,
                        }}
                        onChange={(e) =>
                            handleToggle(e.value, e.field, e.dataItem)
                        }
                    />
                )}
            />
            <GridColumn width={300} field={"name"} title={"Access"} />
            {columns.map((column) => (
                <GridColumn
                    key={Math.random()}
                    field={`${column}.hasPermission`}
                    title={StringHelper.capitalize(column)}
                    width={160}
                    cell={(props) =>
                        props.dataItem[column] !== undefined ? (
                            <EditableCheckBoxCell
                                {...props}
                                editField={"name"}
                                size={"large"}
                                style={{ borderWidth: 0 }}
                                onChange={(e) =>
                                    handleToggle(e.value, e.field, e.dataItem)
                                }
                            />
                        ) : (
                            <BaseCell {...props} />
                        )
                    }
                />
            ))}
        </DataGrid>
    );
};

export default BaseContent;
