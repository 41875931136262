import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../../../components/Deprecated/ColumnMenu";
import React, { useContext, useEffect, useState } from "react";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import {
    deleteVendorItem,
    getVendorItemsByItemId,
} from "../../../../services/Deprecated/inventory/vendorService";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import AssignVendorForm from "./AssignVendorForm";
import { useModal } from "../../../../hooks/useModal";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { ConfirmationDialog } from "../../../../components/Dialogs/ConfirmationDialog";
import VendorItemModal from "../../Vendor/Components/VendorItemModal";
import { Card } from "@progress/kendo-react-layout";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";

const ItemVendorsGrid = ({ item }) => {
    const [vendorItems, setVendorItems] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [internalReloadTrigger, setInternalReloadTrigger] = useState(false);
    const { reloadData } = useContext(ReloadDataContext);
    const itemId = item.id;
    const addExistingModal = useModal();
    const confirmationDialog = useModal();

    const closeAssignVendorForm = () => {
        setShowAddForm(false);
        setInternalReloadTrigger(!internalReloadTrigger);
    };

    useEffect(() => {
        const fetchVendorsForItem = async () => {
            const itemId = item.id;
            try {
                if (!itemId) return; // Check if itemId is available

                // Fetch vendor items directly by item ID
                const response = await getVendorItemsByItemId(itemId);
                const vendorItems = response.data;

                setVendorItems(vendorItems);
            } catch (error) {
                console.error("Error fetching vendorItems:", error);
            }
        };

        fetchVendorsForItem();
    }, [itemId, reloadData, internalReloadTrigger]);

    return (
        <Card>
            <span
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                }}
            >
                <ActionButton
                    requiredPermissions={[PermissionsEnum.CreateVendor]}
                    icon={"k-icon k-i-plus"}
                    onClick={() => setShowAddForm(true)}
                    style={{
                        width: "140px",
                        margin: "5px",
                    }}
                >
                    Assign Vendor
                </ActionButton>
            </span>
            {/* Conditionally render the AssignVendorForm based on showAddForm state */}
            {showAddForm && (
                <AssignVendorForm
                    closeForm={closeAssignVendorForm}
                    item={item}
                    vendorItems={vendorItems}
                    reloadAfterSubmit={false}
                />
            )}
            <VendorItemModal
                {...addExistingModal}
                reloadAfterSubmit={false}
                close={() => {
                    // custom overwrite of close to allow internal reload trigger
                    addExistingModal.setId(undefined);
                    addExistingModal.setRecord(undefined);
                    addExistingModal.setVisible(false);
                    setInternalReloadTrigger(!internalReloadTrigger);
                }}
            />
            <ConfirmationDialog
                {...confirmationDialog}
                actionOnConfirm={() => {
                    const { vendorId, itemId } = confirmationDialog.record;
                    console.log("deleting item from vendor", {
                        vendorId,
                        itemId,
                    });
                    confirmationDialog.close();
                    deleteVendorItem(vendorId, itemId).then(() => {
                        setInternalReloadTrigger(!internalReloadTrigger);
                    });
                }}
                actionOnCancel={() => confirmationDialog.close()}
                confirmationText={"Are you sure you wish to delete?"}
            />

            <Grid
                className="card-container"
                data={vendorItems}
                style={{
                    margin: "0",
                }}
            >
                <GridColumn
                    field="vendor.name"
                    title="Vendor Name:"
                    filter={"text"}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Inventory/Vendor/${props.dataItem.vendor.id}`}
                        />
                    )}
                />
                <GridColumn
                    field="vendorCost.amount"
                    title="Cost"
                    filter={"text"}
                    format="{0:c}"
                    columnMenu={ColumnMenu}
                    width={150}
                />
                <GridColumn
                    field="freightCharge.amount"
                    title="Freight"
                    filter={"text"}
                    format="{0:c}"
                    columnMenu={ColumnMenu}
                    width={150}
                />
                <GridColumn
                    width={125}
                    cell={(props) => (
                        <ActionCell
                            onEdit={() =>
                                addExistingModal.open(
                                    props.dataItem.id,
                                    props.dataItem
                                )
                            }
                            permissions={{
                                edit: [PermissionsEnum.EditVendor],
                            }}
                            // Using custom delete button to avoid weird issues
                            additionalActions={[
                                {
                                    text: "Delete",
                                    onClick: () =>
                                        confirmationDialog.open(
                                            props.dataItem.id,
                                            {
                                                vendorId:
                                                    props.dataItem.vendor.id,
                                                itemId: props.dataItem.item.id,
                                            }
                                        ),
                                    requiredPermissions: [
                                        PermissionsEnum.EditItem,
                                    ],
                                },
                            ]}
                        />
                    )}
                />
            </Grid>
        </Card>
    );
};

export default ItemVendorsGrid;
