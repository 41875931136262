import { PermissionsEnum } from "./Enums/PermissionsEnum.js";

export default class PermissionsHelper {
    static NAV_USER_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewUser];
    static NAV_ROLE_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewRole];
    static NAV_CUSTOMER_CONFIG_OPTIONAL_PERMISSIONS = [
        PermissionsEnum.ViewBusinessInformation,
        PermissionsEnum.ViewCommunicationType,
        PermissionsEnum.ViewSource,
        PermissionsEnum.ViewTaskCategory,
        PermissionsEnum.ViewTask,
        PermissionsEnum.ViewEventType,
    ];
    static NAV_EMPLOYEE_CONFIG_OPTIONAL_PERMISSIONS = [
        PermissionsEnum.GeneratePayroll,
        PermissionsEnum.ViewIdentificationType,
        PermissionsEnum.ViewLaborType,
        PermissionsEnum.ViewLocation,
        PermissionsEnum.ViewMaritalStatus,
    ];
    static NAV_INVENTORY_CONFIG_OPTIONAL_PERMISSIONS = [
        PermissionsEnum.ViewStorageLocation,
        PermissionsEnum.ViewMaritalStatus,
        PermissionsEnum.ViewBillingTerm,
        PermissionsEnum.ViewBillingAdjustment,
    ];
    static NAV_GENERAL_CONFIG_OPTIONAL_PERMISSIONS = [
        PermissionsEnum.ViewItemType,
        PermissionsEnum.ViewPhoneType,
        PermissionsEnum.ViewPaymentType,
        PermissionsEnum.ViewSocialMedia,
        PermissionsEnum.ViewUnitOfMeasure,
    ];
    static NAV_COA_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewAccount];
    static NAV_JOURNAL_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewTransaction];
    static NAV_BALANCE_SHEET_REQUIRED_PERMISSIONS = [
        PermissionsEnum.ViewBalanceSheet,
    ];
    static NAV_INCOME_STATEMENT_REQUIRED_PERMISSIONS = [
        PermissionsEnum.ViewIncomeStatement,
    ];
    static NAV_EMPLOYEE_DASHBOARD_REQUIRED_PERMISSIONS = [
        PermissionsEnum.ViewEmployee,
    ];
    static NAV_ITEM_CATEGORIES_REQUIRED_PERMISSIONS = [
        PermissionsEnum.ViewItem,
    ];
    static NAV_ALL_ITEMS_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewItem];
    static NAV_INVENTORY_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewInventory];
    static NAV_CUSTOMER_DASHBOARD_REQUIRED_PERMISSIONS = [
        PermissionsEnum.ViewCustomer,
    ];
    static NAV_JOB_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewJob];
    static NAV_LEADS_DASHBOARD_REQUIRED_PERMISSIONS = [
        PermissionsEnum.ViewLead,
    ];
    static NAV_PAYROLL_REQUIRED_PERMISSIONS = [PermissionsEnum.GeneratePayroll];
    static NAV_VENDORS_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewVendor];
    static NAV_SCHEDULER_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewScheduler];
    static NAV_MY_WORK_REQUIRED_PERMISSIONS = [PermissionsEnum.ViewTimeClock];
    static NAV_TIME_CLOCK_REQUIRED_PERMISSIONS = [
        PermissionsEnum.ViewTimeClock,
    ];

    /**
     * @function hasPermission
     * @description Checks if the current user has the required permissions or optional permissions
     * @param userPermissions - Array of user permissions
     * @param [requiredPermissions] - Array of required permissions
     * @param [optionalPermissions] - Array of optional permissions
     * @return {boolean}
     */
    static hasPermission = (
        userPermissions,
        requiredPermissions = [],
        optionalPermissions = undefined
    ) => {
        if (!userPermissions) {
            throw new Error("User permissions are required");
        }

        if (requiredPermissions.length === 0 && !optionalPermissions) {
            return true;
        }

        if (requiredPermissions.length > 0) {
            const hasRequiredPermissions = requiredPermissions.every(
                (rPermId) => {
                    return userPermissions.some((uPerm) => {
                        return uPerm.id.toUpperCase() === rPermId.toUpperCase();
                    });
                }
            );

            if (hasRequiredPermissions) return true;
        }

        if (optionalPermissions) {
            const hasOptionalPermissions = optionalPermissions.some(
                (oPermId) => {
                    return userPermissions.some((uPerm) => {
                        return uPerm.id.toUpperCase() === oPermId.toUpperCase();
                    });
                }
            );

            if (hasOptionalPermissions) return true;
        }

        return false;
    };
}
